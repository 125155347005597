import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguageConditions from "../hooks/useLanguageConditions";

const NavButtons = ({ show }) => {
  const { t, i18n } = useTranslation();
  const { isCA, isUK, isUS } = useLanguageConditions();
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallDevice = window.innerWidth < 1024;
  const [isPatientMenuActive, setIsPatientMenuActive] = useState(false);
  const [isProviderMenuActive, setIsProviderMenuActive] = useState(false);
  const [showProviderList, setShowProviderList] = useState(false);
  const [showPatientList, setShowPatientList] = useState(false);
  const isAuth = useSelector((state) => state.auth.mfaEnabled);
  const userType = useSelector((state) => state.auth.userType);
  const [showPatientMenu, setshowPatientMenu] = useState(true);
  const [showProviderMenu, setshowProviderMenu] = useState(true);
  useEffect(() => {
    setIsPatientMenuActive(false);
    setIsProviderMenuActive(false);
    if (location.pathname.includes("/hcp")) setIsProviderMenuActive(true);
    if (location.pathname.includes("/patient")) setIsPatientMenuActive(true);
  }, [location]);

  useEffect(() => {
    if (isAuth && userType !== "AdminUser")
      if (userType === "User") {
        setshowProviderMenu(true);
        setshowPatientMenu(false);
      } else {
        setshowProviderMenu(false);
        setshowPatientMenu(true);
      }
    else {
      setshowProviderMenu(true);
      setshowPatientMenu(true);
    }
  }, [isAuth]);
  return (
    <div className="row w-100 m-0">
      {showProviderMenu && (
        <div
          className={`col-12 p-0 position-relative col-lg-${
            !showProviderMenu || !isAuth || userType === "AdminUser"
              ? "6"
              : "12"
          }`}
          tabIndex={0}
          onBlur={() => setTimeout(() => setShowProviderList(false), 300)}
        >
          <button
            onClick={() => {
              setShowPatientList(false);
              setShowProviderList(!showProviderList);
            }}
            className={`btn btn-primary w-100  p-2 text-white fw-bold text-center br-none menu-btn ${
              isProviderMenuActive && "menu-btn__active"
            }`}
            style={{ fontSize: isSmallDevice ? 14 : 18 }}
          >
            <span>{t("header_btn_3")}</span>{" "}
            <i
              className="fas fa-chevron-down  transition-all"
              style={{
                transform: showProviderList
                  ? "rotate(-180deg)"
                  : "rotate(0deg)",
              }}
            ></i>
          </button>
          {showProviderList && (
            <div
              className={`${
                !showProviderMenu || !isAuth
                  ? ""
                  : "container-xxl px-0 px-xxl-3"
              }`}
            >
              <div className="position-relative">
                <ul
                  tabIndex={0}
                  className="position-absolute pgs-menu-list"
                  style={{
                    marginTop: isSmallDevice && showPatientMenu ? 50 : 0,
                  }}
                >
                  <NavLink
                    to={
                      isUK
                        ? "/hcp/about-puregenomics"
                        : isCA
                        ? "/hcp/discover-pureinsight"
                        : "/hcp/about-pureinsight"
                    }
                  >
                    <li className="pgs-menu-list__item">{t("header_btn_4")}</li>
                  </NavLink>
                  {isUK ? (
                    <>
                      {" "}
                      <NavLink to={"/hcp/snps-genetic-testing"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_5")}
                        </li>
                      </NavLink>
                      <NavLink
                        to={{ pathname: "/hcp/educational-page", api: true }}
                      >
                        <li className="pgs-menu-list__item">
                          {t("header_btn_6")}
                        </li>
                      </NavLink>
                      <NavLink to={"/hcp/nutritional-support"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_7")}
                        </li>
                      </NavLink>
                      <NavLink to={"/hcp/getting-started"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_8")}
                        </li>
                      </NavLink>
                      {!isAuth && (
                        <NavLink to="/hcp/registration">
                          <li className="pgs-menu-list__item">
                            {t("register")}
                          </li>
                        </NavLink>
                      )}{" "}
                    </>
                  ) : (
                    <></>
                  )}
                  <NavLink to={isCA ? "hcp-faqs" : "/hcp/faqs"}>
                    <li className="pgs-menu-list__item">{t("header_btn_9")}</li>
                  </NavLink>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
      {showPatientMenu && (
        <div
          className={`col-12 p-0 position-relative col-lg-${
            !showPatientMenu || !isAuth || userType === "AdminUser" ? "6" : "12"
          }`}
          tabIndex={1}
          onBlur={() => setTimeout(() => setShowPatientList(false), 300)}
        >
          <button
            onClick={() => {
              setShowProviderList(false);
              setShowPatientList(!showPatientList);
            }}
            className={`btn btn-primary__p w-100 p-2  text-white fw-bold text-center  transition-all cp br-none menu-btn ${
              isPatientMenuActive && "menu-btn__active"
            }`}
            style={{ fontSize: isSmallDevice ? 14 : 18 }}
          >
            <span>{t("header_btn_10")}</span>{" "}
            <i
              className="fas fa-chevron-down transition-all"
              style={{
                transform: showPatientList ? "rotate(-180deg)" : "rotate(0deg)",
              }}
            ></i>
          </button>
          {showPatientList && (
            <div
              className={`${
                !showPatientMenu || !isAuth ? "" : "container-xxl px-0 px-xxl-3"
              }`}
            >
              <div className="position-relative">
                <ul className="position-absolute pgs-menu-list" tabIndex={1}>
                  <NavLink
                    to={
                      isUK
                        ? "/patient/why-puregenomics"
                        : "/patient/why-pureinsight"
                    }
                  >
                    <li className="pgs-menu-list__item">
                      {t("header_btn_11")}
                    </li>
                  </NavLink>
                  {isUK ? (
                    <>
                      <NavLink to={"/patient/what-you-get"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_12")}
                        </li>
                      </NavLink>
                      <NavLink to={"/patient/how-to-get-started"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_8")}
                        </li>
                      </NavLink>
                      <NavLink to={"/patient/nutritional-support"}>
                        <li className="pgs-menu-list__item">
                          {t("header_btn_7")}
                        </li>
                      </NavLink>{" "}
                    </>
                  ) : (
                    <>
                      <NavLink
                        to="/patient/self-register?show_ps=true"
                        aria-label="Provider Search"
                        className="pg4-dropdown-menu__item"
                        role="menuitem"
                      >
                        <li className="pgs-menu-list__item">
                          Provider Search
                        </li>
                      </NavLink>
                    </>
                  )}
                  <NavLink to={isCA ? "/patient-faqs" : "/patient/faqs"}>
                    <li className="pgs-menu-list__item">
                      {t("header_btn_13")}
                    </li>
                  </NavLink>
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NavButtons;